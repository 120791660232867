#contact {
	//padding: 100px 0 60px;
	background: linear-gradient(to right, #1d193f 0%, #14112c 100%);
	color: rgba(255, 255, 255, 0.75);
	.section-title {
		margin-bottom: 40px;
		p {
			font-size: 16px;
		}
		h2 {
			&::after {
				position: absolute;
				content: "";
				background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
				height: 4px;
				width: 60px;
				bottom: 0;
				margin-left: -30px;
				left: 50%;
			}
		}
	}
	iframe{
		width: 560px;
		height: 500px;
	}
	h2 {
		color: #fff;
		text-align: center;
	}
	#p {
		color: #fff;
		text-align: center;
	}
	h3 {
		color: #fff;
		margin-top: 80px;
		margin-bottom: 25px;
		padding-bottom: 20px;
		font-weight: 400;
	}
	form {
		padding-top: 20px;
	}
	.text-danger {
		color: #cc0033;
		text-align: left;
	}
	.btn-custom {
		margin: 30px 0;
		background: transparent;
		border: 2px solid #fff;
		&:hover {
			color: #1f386e;
			background: #fff;
		}
	}
	.form-control {
		display: block;
		width: 100%;
		padding: 6px 12px;
		font-size: 16px;
		line-height: 1.42857143;
		color: #444;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ddd;
		border-radius: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
		&:focus {
			border-color: #999;
			outline: 0;
			-webkit-box-shadow: transparent;
			box-shadow: transparent;
		}
	}
	.contact-item {
		margin: 20px 0;
		span {
			color: rgba(255, 255, 255, 1);
			margin-bottom: 10px;
			display: block;
		}
		i.fa {
			margin-right: 10px;
		}
	}
	.social {
		border-top: 1px solid rgba(255, 255, 255, 0.15);
		padding-top: 50px;
		margin-top: 50px;
		text-align: center;
		ul {
			li {
				display: inline-block;
				margin: 0 20px;
			}
		}
		i.fa {
			font-size: 22px;
			width: 48px;
			height: 48px;
			padding: 12px 0;
			border: 2px solid #fff;
			color: #fff;
			border-radius: 50%;
			transition: all 0.3s;
			&:hover {
				color: #608dfd;
				background: #fff;
			}
		}
	}
	@media (max-width: 1200px) {
		iframe{
			width: 460px;
			height: 400px;
		}
	}
	@media (max-width: 550px) {
		iframe{
			width: 360px;
			height: 300px;
		}
	}
	@media (max-width: 400px) {
		iframe{
			width: 260px;
			height: 200px;
		}
	}
}