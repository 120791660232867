@import 'https://fonts.googleapis.com/css?family=Alfa+Slab+One';

$img : '../img/404.gif';

.FourOhFour {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    position: absolute;
    top: 20%;
    color: #ffffff;
    font-size: xx-large;
  }
  a {
    position: absolute;
    top: 10%;
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    color: #ffffff;
    font-size: xx-large;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    mix-blend-mode: difference;
    background: url($img) ;
    background-size: cover;
  }
  
  .code {
    font-family: 'Alfa Slab One', cursive;
    font-size: 144px;
    height: 100vh;
    color: white;
    width: 100%;
    display: flex;
    top: 400px;
    background-position: center;
    align-items: center;
    background-size: cover;
    justify-content: center;
  }
}