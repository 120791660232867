#workas {
	padding: 100px 0;
	h4 {
		margin: 5px 0;
	}
	.section-title {
		h2 {
			color: black;
		}
		p {
			color: black;
		}
	}
	.team-img {
		width: 240px;
	}
	.thumbnail {
		background: transparent;
		border: 0;
		.caption {
			padding: 10px 0 0;
			color: #888;
		}
	}
}