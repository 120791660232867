#about {
	padding: 170px 0;
	h3 {
		font-size: 22px;
		margin: 0 0 20px;
	}
	h2 {
		position: relative;
		margin-bottom: 15px;
		padding-bottom: 15px;
		&::after {
			position: absolute;
			content: "";
			background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
			height: 4px;
			width: 60px;
			bottom: 0;
			left: 0;
		}
	}
	.about-text {
		h2 {
			color: black;
		}
		p {
			color: black;
		}
		li {
			margin-bottom: 6px;
			margin-left: 6px;
			list-style: none;
			padding: 0;
			color: black;
			&:before {
				content: "\f00c";
				font-family: "FontAwesome";
				color: #5ca9fb;
				font-size: 11px;
				font-weight: 300;
				padding-right: 8px;
			}
		}
	}
	img {
		width: 520px;
		margin-top: 10px;
		background: #fff;
		border-right: 0;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
	}
	p {
		line-height: 24px;
		margin: 30px 0;
	}
}