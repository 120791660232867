#testimonials {
	padding: 100px 0;
	background: linear-gradient(to right, #211c47 0%, #14112c 100%);
	.section-title {
		h2 {
			color: white;
		}
		p {
			color: white;
		}
	}
	i {
		color: #e6e6e6;
		font-size: 32px;
		margin-bottom: 20px;
	}
}