#footer {
	background: #888888;
	padding: 5px 0;
	p {
		color: rgb(0, 0, 0);
		font-size: 14px;
	}
	a {
		color: #35c8e9;
		&:hover {
			border-bottom: 2px solid #608dfd;
		}
	}
}