#sections {
    margin: 0px;
}
.containers {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: skew(5deg);
  
    .card {
      flex: 1;
      transition: all 1s ease-in-out;
      height: 75vmin;
      position: relative;
      .card__head {
        color:white ;
        background: rgb(114 100 231 / 99%);
        padding: 0.5em;
        transform: rotate(-90deg);
        transform-origin: 0% 0%;
        transition: all 0.5s ease-in-out;
        min-width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 1em;
        white-space: nowrap;
      }
  
      &:hover {
        flex-grow: 10;
        img {
          filter: grayscale(0);
        }
        .card__head {
          text-align: center;
          top: calc(100% - 2em);
          color: white;
          background: rgba(0, 0, 0, 0.5);
          font-size: 2em;
          transform: rotate(0deg) skew(-5deg);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease-in-out;
        filter: grayscale(100%);
      }
      &:not(:nth-child(5)) {
        margin-right: 1em;
      }
    }
  }