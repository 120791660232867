#services {
	padding: 100px 0;
	background: linear-gradient(to right, #211c47 0%, #14112c 100%);
	color: #fff;
	.service-desc {
		margin: 10px 10px 20px;
		margin-bottom: 40px;
	}
	h2 {
		color: #fff;
	}
	.section-title {
		h2 {
			&::after {
				position: absolute;
				content: "";
				background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
				height: 4px;
				width: 60px;
				bottom: 0;
				margin-left: -30px;
				left: 50%;
			}
		}
	}
	i.fa {
		font-size: 42px;
		width: 120px;
		height: 120px;
		padding: 40px 0;
		background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
		border-radius: 50%;
		color: #fff;
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
	}
	h3 {
		font-weight: 500;
		font-size: 20px;
		font-style: oblique;
		padding: 5px 0;
		color: #0098f3;
	}
	p {
		color: rgba(255, 255, 255, 0.75);
	}
	hr {
		background: #6099bb;
	}
}