#portfolio {
	padding: 100px 0;
	.section-title {
		h2 {
			color: black;
		}
		p {
			color: black;
		}
	}
}
#portfoliogallery {
	//height: 700px;
}