#features {
	padding: 100px 0;
	background: linear-gradient(to right, #211c47 0%, #14112c 100%);
	.section-title {
		h2 {
			color: white;
		}
	}
	img {
		width: 50px;
		height: 50px;
	}
	.row {
		h3 {
			color: rgb(197, 197, 197);
		}
		p {
			color: white;
		}
	}
	i.fa {
		font-size: 38px;
		margin-bottom: 20px;
		transition: all 0.5s;
		color: #fff;
		width: 100px;
		height: 100px;
		padding: 20px 0;
		border-radius: 50%;
		background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
	}
}