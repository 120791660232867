body {
	font-family: "Open Sans", sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
}
html {
	font-family: "Open Sans", sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
}
h2 {
	font-family: "Raleway", sans-serif;
	text-transform: uppercase;
	margin: 0 0 20px;
	font-weight: 800;
	font-size: 36px;
	color: rgb(167, 167, 167);
}
h3 {
	font-family: "Raleway", sans-serif;
	font-size: 20px;
	font-weight: 600;
	color: #333;
}
h4 {
	font-family: "Raleway", sans-serif;
	font-size: 18px;
	color: #333;
	font-weight: 600;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p {
	font-size: 15px;
}
p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
	color: #608dfd;
	font-weight: 400;
	&:hover {
		text-decoration: none;
		color: #608dfd;
	}
	&:focus {
		text-decoration: none;
		color: #608dfd;
		outline: none;
		outline-offset: none;
	}
}
ul {
	list-style: none;
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}
ol {
	list-style: none;
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}
hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #1e7a46;
	margin-bottom: 20px;
	border: 0;
}

.navbar-default {
	.navbar-nav {
		>.active {
			>a {
				background-color: transparent;
				&:hover {
					background-color: transparent;
					&:after {
						display: block !important;
						position: absolute !important;
						left: 0 !important;
						bottom: -1px !important;
						width: 100% !important;
						height: 2px !important;
						background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
						content: "" !important;
						transition: width 0.2s !important;
					}
				}
				&:focus {
					background-color: transparent;
					&:after {
						display: block !important;
						position: absolute !important;
						left: 0 !important;
						bottom: -1px !important;
						width: 100% !important;
						height: 2px !important;
						background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
						content: "" !important;
						transition: width 0.2s !important;
					}
				}
				&:after {
					display: block !important;
					position: absolute !important;
					left: 0 !important;
					bottom: -1px !important;
					width: 100% !important;
					height: 2px !important;
					background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
					content: "" !important;
					transition: width 0.2s !important;
				}
			}
		}
	}
	.navbar-toggle {
		&:hover {
			background-color: #fff;
			border-color: #608dfd;
			>.icon-bar {
				background-color: #608dfd;
			}
		}
		&:focus {
			background-color: #fff;
			border-color: #608dfd;
		}
	}
}
.navbar-toggle {
	border-radius: 0;
}
.section-title {
	margin-bottom: 70px;
	h2 {
		position: relative;
		margin-top: 10px;
		margin-bottom: 15px;
		padding-bottom: 15px;
		&::after {
			position: absolute;
			content: "";
			background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
			height: 4px;
			width: 60px;
			bottom: 0;
			margin-left: -30px;
			left: 50%;
		}
	}
	p {
		font-size: 18px;
	}
}
.btn-custom {
	font-family: "Raleway", sans-serif;
	text-transform: uppercase;
	color: #fff;
	background-color: #5ca9fb;
	background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 15px;
	font-weight: 500;
	border-radius: 25px;
	transition: all 0.5s linear;
	border: 0;
	&:hover {
		color: #fff;
		background-image: none;
		background-color: #6372ff;
	}
	&:focus {
		color: #fff;
		background-image: none;
		background-color: #6372ff;
	}
	&:active {
		color: #fff;
		background-image: none;
		background-color: #6372ff;
	}
}
.btn-custom.focus {
	color: #fff;
	background-image: none;
	background-color: #6372ff;
}
.btn-custom.active {
	color: #fff;
	background-image: none;
	background-color: #6372ff;
}
.btn {
	&:active {
		background-image: none;
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		&:focus {
			outline: none;
			outline-offset: none;
		}
	}
	&:focus {
		outline: none;
		outline-offset: none;
	}
	&:active.focus {
		outline: none;
		outline-offset: none;
	}
}
.btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	&:focus {
		outline: none;
		outline-offset: none;
	}
}
.btn.focus {
	outline: none;
	outline-offset: none;
}
.btn.active.focus {
	outline: none;
	outline-offset: none;
}
.intro {
	display: table;
	width: 100%;
	padding: 0;
	background: url('../img/intro-bg.jpg') center center no-repeat;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	.overlay {
		background: rgba(0, 0, 0, 0.60);
	}
	h1 {
		font-family: "Raleway", sans-serif;
		color: #fff;
		font-size: 55px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 10px;
		span {
			font-weight: 800;
			color: #5ca9fb;
		}
	}
	p {
		color: #fff;
		font-size: 22px;
		font-weight: 300;
		line-height: 30px;
		margin: 0 auto;
		margin-bottom: 60px;
	}
}
header {
	.intro-text {
		padding-top: 350px;
		padding-bottom: 200px;
		text-align: center;
	}
}
.portfolio-item {
	margin: 1px -15px 0 -14px;
	padding: 0;
	.hover-bg {
		overflow: hidden;
		position: relative;
		margin: 0;
	}
}
.hover-bg {
	.hover-text {
		position: absolute;
		text-align: center;
		margin: 0 auto;
		color: #fff;
		background: linear-gradient( to right, rgba(99, 114, 255, 0.8) 0%, rgba(92, 169, 251, 0.8) 100% );
		padding: 30% 0 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: all 0.5s;
		>h4 {
			opacity: 0;
			color: #fff;
			-webkit-transform: translateY(100%);
			transform: translateY(100%);
			transition: all 0.3s;
			font-size: 18px;
			letter-spacing: 1px;
			font-weight: 500;
			text-transform: uppercase;
		}
	}
	&:hover {
		.hover-text {
			>h4 {
				opacity: 1;
				-webkit-backface-visibility: hidden;
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}
			opacity: 1;
		}
	}
}

.testimonial {
	position: relative;
	padding: 20px;
	p {
		color: white;
	}
}
.testimonial-image {
	float: left;
	margin-right: 15px;
	display: block;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	img {
		display: block;
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}
}
.testimonial-content {
	position: relative;
	overflow: hidden;
	p {
		margin-bottom: 0;
		font-size: 14px;
		font-style: italic;
	}
}
.testimonial-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #666;
}

label {
	font-size: 12px;
	font-weight: 400;
	font-family: "Open Sans", sans-serif;
	float: left;
}
.form-control {
	&::-webkit-input-placeholder {
		color: #777;
	}
	&:-moz-placeholder {
		color: #777;
	}
	&::-moz-placeholder {
		color: #777;
	}
	&:-ms-input-placeholder {
		color: #777;
	}
}

@media (max-width: 768px) {
	#about {
		img {
			margin: 50px 0;
		}
	}
}
